html {
  height: 100%;
  scroll-padding-top: calc(4.5rem - 1px);
}

body {
  width: 100%;
  height: 100%;
}

#root {
  flex-direction: column !important;
  min-height: 100vh !important;
  display: flex !important;
}

.page-section {
  padding: 6rem 0;
}

@media print {
  header:not(#home), footer, #mainNav {
    display: none;
  }

  .animation {
    opacity: 1 !important;
  }

  #cconsent-bar {
    display: none !important;
  }

  #support-page h2, #support-page .nav-tabs, #support-page > hr {
    display: none;
  }
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.btn-xl {
  padding: 1.25rem 2.25rem;
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 10rem;
}
.btn-md {
  padding: 0.7rem 2rem;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 5rem;
}

hr.divider {
  height: 0.2rem;
  max-width: 4rem;
  margin: 1.5rem auto;
  background-color: #f4623a;
  opacity: 1;
}
hr.divider-light {
  height: 0.2rem;
  background-color: #fff;
}

#mainNav {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: #fff;
  transition: background-color 0.2s ease;
}
#mainNav .navbar-brand {
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #212529;
}
#mainNav .navbar-nav .nav-link {
  color: #6c757d;
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 0.9rem;
  padding: 0.75rem 0;
}
#mainNav .navbar-nav .nav-link:hover, #mainNav .navbar-nav .nav-item .nav-link:active {
  color: #f4623a;
}
#mainNav .navbar-nav .nav-link.active {
  color: #f4623a !important;
}
@media (min-width: 992px) {
  #mainNav {
    box-shadow: none;
    background-color: transparent;
  }
  #mainNav .navbar-brand {
    color: rgba(255, 255, 255, 1);
  }
  #mainNav .navbar-brand:hover {
    color: #f4623a;
  }
  #mainNav .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 1);
    padding: 0 1rem;
  }
  #mainNav .navbar-nav .nav-link:hover {
    color: #f4623a;
  }
  #mainNav .navbar-nav .nav-link:last-child {
    padding-right: 0;
  }
  #mainNav.navbar-shrink {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
  #mainNav.navbar-shrink .navbar-brand {
    color: #212529;
  }
  #mainNav.navbar-shrink .navbar-brand:hover {
    color: #f4623a;
  }
  #mainNav.navbar-shrink .navbar-nav .nav-link {
    color: #212529;
  }
  #mainNav.navbar-shrink .navbar-nav .nav-link:hover {
    color: #f4623a;
  }
}

header#home {
  height: 100vh;
  min-height: 40rem;
  padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem);
  background: linear-gradient(to bottom, rgba(92, 77, 66, 0.4) 0%, rgba(92, 77, 66, 0.9) 100%), url("../img/bg-header-home.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
header#home .logo {
  max-height: 300px;
}
header#home div {
  /*font-size: calc(0.4rem + 0.9vw);*/
  font-size: 0.9rem;
}
@media (min-width: 992px) {
  header#home {
    padding-top: 4.5rem;
    padding-bottom: 0;
  }
  header#home div {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  header#home div {
    font-size: 1.1rem;
  }
}
header {
  padding-top: 6rem;
  padding-bottom: calc(5rem - 4.5rem);
  background: linear-gradient(to bottom, rgba(92, 77, 66, 0.4) 0%, rgba(92, 77, 66, 0.9) 100%), url("../img/bg-header-analisys.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
header .logo {
  max-height: 160px;
}
header div {
  font-size: 0.8rem;
}
@media (min-width: 992px) {
  header {
    padding-top: 2rem;
  }
  header div {
    font-size: 0.9rem;
  }
}
@media (min-width: 1200px) {
  header div {
    font-size: 1rem;
  }
}

footer {
  margin-top: auto !important;
  /* background: linear-gradient(to top, rgba(92, 77, 66, 0.1) 0%, rgba(92, 77, 66, 0.9) 100%); */
  background: linear-gradient(to top, rgba(33, 37, 41, 0.7) 0%, rgba(33, 37, 41, 1) 50%);
}
footer .credits {
  font-size: 0.65rem;
}

section {
  font-size: 0.8rem;
}
section .date{
  font-size: 0.9rem;
}
@media (min-width: 992px) {
  section {
    font-size: 0.9rem;
  }
  section .date{
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  section {
    font-size: 1rem;
  }
  section .date{
    font-size: 1.1rem;
  }
}
section#contact {
  background-color: #212529;
  background-image: url("../img/map-image.png");
  background-repeat: no-repeat;
  background-position: center;
}
section#analysis {
  font-size: 0.8rem;
}

#support .container-fluid, #support .container-sm, #support .container-md, #support .container-lg, #support .container-xl, #support .container-xxl {
  max-width: 1920px;
}
#support .container-fluid .support-box, #support .container-sm .support-box, #support .container-md .support-box, #support .container-lg .support-box, #support .container-xl .support-box, #support .container-xxl .support-box {
  position: relative;
  display: block;
}
#support .container-fluid .support-box .support-box-caption, #support .container-sm .support-box .support-box-caption, #support .container-md .support-box .support-box-caption, #support .container-lg .support-box .support-box-caption, #support .container-xl .support-box .support-box-caption, #support .container-xxl .support-box .support-box-caption {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  opacity: 0;
  color: #fff;
  background: rgba(244, 98, 58, 0.9);
  transition: opacity 0.25s ease;
  text-align: center;
}
#support .container-fluid .support-box .support-box-caption .project-category, #support .container-sm .support-box .support-box-caption .project-category, #support .container-md .support-box .support-box-caption .project-category, #support .container-lg .support-box .support-box-caption .project-category, #support .container-xl .support-box .support-box-caption .project-category, #support .container-xxl .support-box .support-box-caption .project-category {
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
}
#support .container-fluid .support-box .support-box-caption .project-name, #support .container-sm .support-box .support-box-caption .project-name, #support .container-md .support-box .support-box-caption .project-name, #support .container-lg .support-box .support-box-caption .project-name, #support .container-xl .support-box .support-box-caption .project-name, #support .container-xxl .support-box .support-box-caption .project-name {
  font-size: 1.2rem;
}
#support .container-fluid .support-box:hover .support-box-caption, #support .container-sm .support-box:hover .support-box-caption, #support .container-md .support-box:hover .support-box-caption, #support .container-lg .support-box:hover .support-box-caption, #support .container-xl .support-box:hover .support-box-caption, #support .container-xxl .support-box:hover .support-box-caption {
  opacity: 1;
}
.support-box-title {
  height: 350px;
  padding-top: 150px;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none!important;
}
.support_tutorial {
  background: linear-gradient(to bottom, rgba(92, 77, 66, 0.2) 0%, rgba(92, 77, 66, 0.9) 100%), url("../img/support/Bg1.jpg");
}
.support_help {
  background: linear-gradient(to bottom, rgba(92, 77, 66, 0.2) 0%, rgba(92, 77, 66, 0.9) 100%), url("../img/support/Bg2.jpg");
}
.support_code {
  background: linear-gradient(to bottom, rgba(92, 77, 66, 0.2) 0%, rgba(92, 77, 66, 0.9) 100%), url("../img/support/Bg3.jpg");
}

.modal-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}
.modal-body ul.list-inline {
  margin-bottom: 2rem;
}

.form-control, .filepond--root {
  margin-bottom: .1em!important;
}
.filepond--panel-root {
  border: 1px solid #ced4da;
}
.was-validated input[type="file"]:invalid + .filepond--drop-label + .filepond--list-scroller + .filepond--panel-root,
.was-validated input[type="file"]:invalid + .filepond--drop-label + .filepond--list-scroller + .filepond--panel .filepond--panel-bottom {
  border: 1px solid #dc3545!important;
}
.was-validated input[type="file"]:invalid + .filepond--drop-label {
  padding-right: 1em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated input[type="file"]:valid + .filepond--drop-label + .filepond--list-scroller + .filepond--panel-root,
.was-validated input[type="file"]:valid + .filepond--drop-label + .filepond--list-scroller + .filepond--panel .filepond--panel-bottom {
  border: 1px solid #198754!important;
}
.filepond--root-multiple {
  max-height: 17rem;
  overflow: auto;
}

.header-markers {
  font-size: 0.8rem !important;
}
.list-group-item-markers {
  color: #000;
  background-color: #f9f2ec;
  padding: 0.4em 0.8em;
  font-size: 0.6rem !important;
  text-align: center !important;
  font-weight: 700 !important;
  cursor: pointer;
}
.list-group-item-markers.list-group-item:hover, .list-group-item-markers.list-group-item:focus {
  color: #fff;
  background-color: #959390;
  border-color: #959390;
}
.list-group-item-markers.list-group-item.active {
  color: #959390;
  background-color: #e5e4e3;
}

.animation {
  opacity: 0;
}

.fs-7 {
  font-size: 0.9rem !important;
}
.fs-8 {
  font-size: 0.8rem !important;
}
.fs-9 {
  font-size: 0.7rem !important;
}
.fs-10 {
  font-size: 0.6rem !important;
}

.fs-a {
  font-size: 0.9rem !important;
}
.fs-b {
  font-size: 0.8rem !important;
}
.fs-c {
  font-size: 0.7rem !important;
}
.fs-d {
  font-size: 0.6rem !important;
}
.fs-e {
  font-size: 0.5rem !important;
}
@media (min-width: 992px) {
  .fs-a {
    font-size: 0.95rem !important;
  }
  .fs-b {
    font-size: 0.85rem !important;
  }
  .fs-c {
    font-size: 0.75rem !important;
  }
  .fs-d {
    font-size: 0.65rem !important;
  }
  .fs-e {
    font-size: 0.55rem !important;
  }
}
@media (min-width: 1200px) {
  .fs-a {
    font-size: 1rem !important;
  }
  .fs-b {
    font-size: 0.9rem !important;
  }
  .fs-c {
    font-size: 0.8rem !important;
  }
  .fs-d {
    font-size: 0.7rem !important;
  }
  .fs-e {
    font-size: 0.6rem !important;
  }
}
