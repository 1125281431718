:root {
  --bs-orange: #f4623a;
  --bs-primary: #f4623a;
  --bs-secondary: #767f87;
  --bs-warning: #ffc107;
  --bs-primary-rgb: 244, 98, 58;
  --bs-secondary-rgb: 118, 127, 135;
  --bs-warning-rgb: 255, 193, 7;
  --bs-font-sans-serif: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
a {
  color: var(--bs-primary);
  text-decoration: none;
}
a:hover {
  color: #c34e2e;
}

.form-label {
  font-weight: bold;
}
.form-text {
  font-size: 0.775em;
}
.form-control:focus {
  border-color: #fab19d;
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.form-select:focus {
  border-color: #fab19d;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-check-input:focus {
  border-color: #fab19d;
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: #f4623a;
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.form-switch .form-check-input {
  width: 3em;
  height: 1.7em;
  margin-top: 0;
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fab19d'/%3e%3c/svg%3e");
}
.form-switch .form-check-label {
  padding-left: 0.5rem;
}
.form-range {
  height: inherit;
  padding-top: 0.5rem;
}
.form-range:focus::-webkit-slider-thumb {
   box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.form-range::-webkit-slider-thumb {
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--bs-primary);
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fcd0c4;
}
.form-range::-moz-range-thumb {
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--bs-primary);
}
.form-range::-moz-range-thumb:active {
  background-color: #fcd0c4;
}
.form-range::-webkit-slider-runnable-track {
  height: 0.6rem;
}
.form-range::-moz-range-track {
  height: 0.6rem;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #babec3;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #babec3;
}

.btn-check:focus + .btn, .btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.btn-primary {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.btn-primary:hover {
  background-color: #cf5331;
  border-color: #c34e2e;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  background-color: #cf5331;
  border-color: #c34e2e;
  box-shadow: 0 0 0 0.25rem rgba(246, 122, 88, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  background-color: #c34e2e;
  border-color: #b74a2c;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(246, 122, 88, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.btn-warning {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #fff;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-light:hover {
  background-color: #ebebeb;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  background-color: #ebebeb;
  border-color: #f8f9fa;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.btn-dark:hover {
  background-color: #000000;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  background-color: #000000;
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  background-color: #000000;
}
.btn-blue {
  color: #fff;
  background-color: #325d88;
  border-color: #325d88;
}
.btn-blue:hover {
  color: #fff;
  background-color: #2b4f74;
  border-color: #284a6d;
}
.btn-check:focus + .btn-blue, .btn-blue:focus {
  color: #fff;
  background-color: #2b4f74;
  border-color: #284a6d;
  box-shadow: 0 0 0 0.25rem rgba(81, 117, 154, 0.5);
}
.btn-check:checked + .btn-blue, .btn-check:active + .btn-blue, .btn-blue:active, .btn-blue.active, .show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #284a6d;
  border-color: #264666;
}
.btn-check:checked + .btn-blue:focus, .btn-check:active + .btn-blue:focus, .btn-blue:active:focus, .btn-blue.active:focus, .show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(81, 117, 154, 0.5);
}
.btn-blue:disabled, .btn-blue.disabled {
  color: #fff;
  background-color: #325d88;
  border-color: #325d88;
}

.btn-outline-primary {
  color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.btn-outline-primary:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: var(--bs-primary);
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
}

.dropdown-menu {
  min-width: 8rem;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: var(--bs-primary);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  background-color: var(--bs-primary);
}

.nav-link {
  color: var(--bs-primary);
}
.nav-link:hover, .nav-link:focus {
  color: #c34e2e;
}

.nav-tabs {
  border-width: 2px;
}
.nav-tabs .nav-link {
  /* background-color: #f8f9fa; */
  /* border-color: #dee2e6; */
  color: var(--bs-primary);
  border-width: 2px;
  height: 106%;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--bs-primary);
}

.accordion-button {
  padding: 0.7rem 1rem;
  background-color: transparent;
}
.accordion-button:not(.collapsed) {
  color: #dc5834!important;
  background-color: #feefeb;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23dc5834'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
}
.accordion-button:focus {
  border-color: #fab19d;
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.marker .accordion-button {
  color: white;
}
.marker .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.page-link {
  color: #0d6efd;
}
.page-link:hover {
  color: #c34e2e;
}
.page-link:focus {
  color: #c34e2e;
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.page-item.active .page-link {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.alert-primary {
  color: #923b23;
  background-color: #fde0d8;
  border-color: #fcd0c4;
}
.alert-primary .alert-link {
  color: #752f1c;
}

.progress {
  height: 1.7rem;
  border-radius: 0.5rem;
}
.progress-bar {
  background-color: var(--bs-primary);
  color: #fff;
}

.list-group-item-primary {
  color: #923b23;
  background-color: #fde0d8;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #923b23;
  background-color: #e4cac2;
}
.list-group-item-primary.list-group-item-action.active {
  background-color: #923b23;
  border-color: #923b23;
}
.list-group-item-light {
  color: #000;
  background-color: #f9f2ec;
}

.btn-close:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}

.link-primary {
  color: var(--bs-primary);
}
.link-primary:hover, .link-primary:focus {
  color: #c34e2e;
}

.border-primary {
  border-color: var(--bs-primary) !important;
}
